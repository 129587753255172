import React from 'react'
import Profile from "../../assets/profile.jpg"
import { Info } from './Info'
import CV from "../../assets/John-Cv.pdf"
import './about.css'
import { ScrollDown } from './ScorollDown'
export const About = () => {
  return (
    <section className="sbout sectiom" id="about">

     <h2 className="section__title">About Me</h2>
       <span className='section__subtitle'>My introduction</span>
       <div className="about__container container grid">

   
      <img src={Profile} alt="" className="about__img" />

      <div className="about__data">
     <Info />
     <p className='about__description'>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Tempora itaque quaerat eos, quod voluptatibus quae explicabo illum recusandae ducimus perferendis.</p>


      </div>
      <div className="scroll">
      <ScrollDown />
      </div>

       </div>
    </section>
  )
}
