import React, { useState, useEffect } from 'react';
import { db } from '../../firebase/firebase';
import { collection, getDocs } from 'firebase/firestore';
import { Container, Grid, Card, CardMedia, CardContent, Typography } from '@mui/material';
import './News.css';

export const News = () => {
  const [posts, setPosts] = useState([]);

  useEffect(() => {
    const fetchPosts = async () => {
      try {
        const querySnapshot = await getDocs(collection(db, 'article'));
        const fetchedPosts = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        setPosts(fetchedPosts);
      } catch (error) {
        console.error('Error fetching posts:', error);
      }
    };

    fetchPosts();
  }, []);

  return (
    <Container maxWidth="lg" className="news-container">
      <Typography variant="h3" component="h1" className="news-title" gutterBottom margin={10}>
        News
      </Typography>
      <Grid container spacing={4}>
        {posts.map(post => (
          <Grid item key={post.id} xs={12} sm={6} md={4}>
            <Card className="news-card">
              <CardMedia
                component="img"
                height="200"
                image={post.imageUrl}
                alt="News Image"
                className="news-image"
              />
              <CardContent className="news-content">
                <Typography variant="h5" component="div" className="news-card-title">
                  {post.title}
                </Typography>
                <Typography variant="body2" color="textSecondary" className="news-article">
                  {post.article}
                </Typography>
                <Typography variant="subtitle2" color="textSecondary" className="news-author">
                  By <strong>{post.author}</strong>
                </Typography>
                <Typography variant="caption" color="textSecondary" className="news-category">
                  {post.category}
                </Typography>
                <Typography variant="caption" color="textSecondary" className="news-date">
                  {post.date}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
};
