import React from 'react'
import './services.css'
export const Services = () => {
  return (
    <section className="service section" id="services">
   <h2 className="section__title">Services</h2>
   <span className='section__subtitle'>What I Offer</span>
    <div className="services__container container grid">
        <div className="services__content">
            <div>
                <i className='uil uil- services__icon'></i>
                <h3 className="services__title"></h3>
            </div>


              <div className="services__modal">
                <div className="services__modal-content">

   
                    <ul className="services__modal-services grid">
                        <li className="services-modal-services">
                            <i className="uil uil-globe services__modal-icon"></i>
                            <h2 className='services__model-title'>Guest Posting</h2>
                            <p className="services__modal-info">My guest posting services are designed to secure high-quality placements on authoritative websites relevant to your industry. We develop compelling and informative guest articles that establish your brand's thought leadership, drive targeted traffic, and build valuable backlinks</p>
                            <a href="#" className='Know'>Know More <i className='uild uil-arrow-right'></i></a>
                        </li>

                        <li className="services-modal-services">
                            <i className="uil uil-link services__modal-icon"></i>
                            <h2 className='services__model-title'>Link Bluiding</h2>
                            <p className="services__modal-info">My team specializes in creating natural and effective link building strategies. Our ethical approach focuses on building long-lasting, high-quality links that improve your website's search engine visibility and drive organic traffic</p>
                            <a href="#" className='Know'>Know More <i className='uild uil-arrow-right'></i></a>
                        </li>

                        <li className="services-modal-services">
                            <i className="uil uil-web-grid services__modal-icon"></i>
                            <h2 className='services__model-title'>Content Creation</h2>
                            <p className="services__modal-info">i have a team of skilled content writers who create engaging and informative articles tailored to the target websites for guest posting. Our content specialists ensure that each piece is well-researched, relevant, and aligned with your brand's voice and values.</p>
                            <a href="#" className='Know'>Know More <i className='uild uil-arrow-right'></i></a>
                        </li>


                    </ul>
                </div>
              </div>

        </div>
    </div>
</section>
  )
}
