import React, { useState } from 'react'
import './Header.css'
import { Link } from 'react-router-dom';
export const Header = () => {

 const[Toggle, showMenu] = useState(false);

  return (
    <header className='header'>
      <nav className='nav container'>
        <a href="index.html" className='nav__logo'>Nasim</a>

            <div className={Toggle ? "nav__menu show-menu":
         "nav__menu"}>

                <ul className="nav__list grid">
                    <li className="nav__item">

                       <Link className='nav__link active-link' to='/'><i className='uil uil-estate nav__icon'></i> Home</Link>

                    </li>
                    <li className="nav__item">
                    <Link className='nav__link active-link' to='/about'><i className='uil uil-user nav__icon'></i>About</Link>
                    </li>
                    <li className="nav__item">
<Link className='nav__link active-link' to='/services'><i className='uil uil-briefcase nav__icon'></i>Services</Link>
                    </li>
                    <li className="nav__item">
                    <Link className='nav__link active-link' to='/contact'><i className='uil uil-message nav__icon'></i>Contact</Link>
                    </li>
                    <li className="nav__item">
                    <Link className='nav__link active-link' to='/news'><i className='uil uil-newspaper nav__icon'></i>News</Link>
                    </li>
                </ul>
                    <i className='uil uil-times nav__close'  onClick={() => showMenu
            (!Toggle)} ></i>
            </div>
            <div className="nav__toggle" onClick={() => showMenu
            (!Toggle)}>
                <i className='uil uil-apps'></i>
            </div>
      </nav>
    </header>
  )
}
