import React from 'react'

export const Social = () => {
  return (
   <div className="home__social">
    <a href="" className='home__social-icon' target='_blank'>
        <i className='uil uil-instagram'></i>
    </a>

    <a href="" className='home__social-icon' target='_blank'>
        <i className='uil uil-globe'></i>
    </a>

    <a href="" className='home__social-icon' target='_blank'>
        <i className='uil uil-skype'></i>
    </a>

    <a href="" className='home__social-icon' target='_blank'>
        <i className='uil uil-whatsapp'></i>
    </a>
   </div>
  )
}
