import React from 'react';
import { createBrowserRouter, Outlet, Route, RouterProvider,} from "react-router-dom";
import './App.css';
import { Header } from './components/header/Header';
import { Home } from './components/Home/Home';
import { About } from './components/about/About';
import { Services } from './components/services/Services';
import { Qualification } from './components/Qualification/Qualification';
import { Contact } from './components/contact/Contact';
import { News } from './components/News/News';
import { Sing } from './components/Single-Page/Sing';
import Login from './components/Login/Login';
import Admin from './components/Admin/Admin';


const Main = () => {
  return (
  <>
  <Header />
  <Home />
  <About />
  <Services />
  <Contact />
  <News />
  </>
  )
}

const Layout  = () => {
     
  return (
     
     <>
     <Header />
     <Outlet />
     </>
    
  )

}



const router = createBrowserRouter ([



  {
    path: "/",
    element: <Layout/>,
    children:[

      {
        path: "/about",
        element: <About />
      },
      {
        path: "/services",
        element: <Services />
      },
 
      {
       path: "/contact",
       element: <Contact />
     },
     {
      path:'/news',
      element: <News />, 
    },

    {
      path:'/sing',
      element: <Sing />, 
    },

      {
        path:'/',
        element: <Main />, 
      },
     

    ]
  },


  {
    path:'/login',
    element:<Login />
  },

//Ai Route Ta Banaisi Kintu Deploy Korar Por Eta Te Dukhe Na 
  
]);











function App() {




  return (

  <>
 
  <RouterProvider router={router} />
  </>

  );
}

export default App;
