// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyDqdRZ0wpy1dDTWqmEng8OGVia-eDfoZBo",
  authDomain: "nasim-digital.firebaseapp.com",
  projectId: "nasim-digital",
  storageBucket: "nasim-digital.appspot.com",
  messagingSenderId: "534719099271",
  appId: "1:534719099271:web:97094d55be6912df3ee322",
  measurementId: "G-RL20CCNKY7"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const db = getFirestore(app)
const auth = getAuth(app)
const storage = getStorage(app)

export { app, auth,db,storage };