import React from 'react'

export const Info = () => {
  return (
   <div className="about__info grid">
    <div className="about-box">
        <i className='bx bx-award about__icon' ></i>
        <h3 className="about__title">Exprerience</h3>
        <span className='about__subtitle'>3 Year Working</span>
    </div>
    <div className="about-box">
    <i class='bx bx-happy about__icon'></i>
        <h3 className="about__title">1000000+</h3>
        <span className='about__subtitle'>Satisfied Customer </span>
    </div>
    <div className="about-box">
    <i class='bx bx-support about__icon'></i>
        <h3 className="about__title">24/7</h3>
        <span className='about__subtitle'>Customer Support</span>
    </div>
   </div>
  )
}
