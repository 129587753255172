import React, { useState, useEffect } from 'react';
import { createUserWithEmailAndPassword, signInWithEmailAndPassword } from 'firebase/auth';
import { auth, db, storage } from '../../firebase/firebase';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { addDoc, collection, getDocs, deleteDoc, doc, updateDoc } from 'firebase/firestore';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import './Login.css';

export function useUserState() {
  const [user, setUser] = useState();
  return { user, setUser };
}

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [addEmail, setAddEmail] = useState('');
  const [addPassword, setAddPassword] = useState('');
  const { user, setUser } = useUserState();
  const [extend, setExtend] = useState(false);
  const [title, setTitle] = useState('');
  const [article, setArticle] = useState('');
  const [imageUrl, setImageUrl] = useState(null);
  const [author, setAuthor] = useState('');
  const [posts, setPosts] = useState([]);
  const [editing, setEditing] = useState(false);
  const [editId, setEditId] = useState(null);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await signInWithEmailAndPassword(auth, email, password);
      toast.success('Login Successful');
      setUser(true);
      fetchPosts(); // Fetch posts after login
    } catch (error) {
      console.error(error);
      toast.error('Authentication Failed');
    }
  };

  const handleReg = async (e) => {
    e.preventDefault();
    try {
      await createUserWithEmailAndPassword(auth, addEmail, addPassword);
      toast.success('User Added!');
    } catch (error) {
      console.error(error);
      toast.error('An error occurred. Please contact support.');
    }
  };

  const handlePost = async (e) => {
    e.preventDefault();
    const res = await addDoc(collection(db, 'article'), {
      title: title,
      article: article,
      imageUrl: imageUrl,
      author: author,
    });
    console.log(res.id);
    toast.success("Article Published");
    fetchPosts(); // Fetch posts after a new article is published
  };

  const handleImageUpload = async (e) => {
    const file = e.target.files[0];
    const storageRef = ref(storage, `images/${file.name}`);
    await uploadBytes(storageRef, file);
    const downloadURL = await getDownloadURL(storageRef);
    setImageUrl(downloadURL);
  };

  const fetchPosts = async () => {
    try {
      const querySnapshot = await getDocs(collection(db, 'article'));
      const fetchedPosts = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setPosts(fetchedPosts);
    } catch (error) {
      console.error('Error fetching posts:', error);
    }
  };

  const handleDelete = async (id) => {
    try {
      await deleteDoc(doc(db, 'article', id));
      toast.success('Article Deleted');
      fetchPosts(); // Fetch posts after an article is deleted
    } catch (error) {
      console.error('Error deleting article:', error);
      toast.error('Error deleting article');
    }
  };

  const handleEdit = (id, title, article, imageUrl, author) => {
    setEditing(true);
    setEditId(id);
    setTitle(title);
    setArticle(article);
    setImageUrl(imageUrl);
    setAuthor(author);
    window.scrollTo({ top: 0, behavior: 'smooth' }); // Scroll to the top of the page
  };

  const handleUpdate = async (e) => {
    e.preventDefault();
    try {
      await updateDoc(doc(db, 'article', editId), {
        title: title,
        article: article,
        imageUrl: imageUrl,
        author: author,
      });
      toast.success('Article Updated');
      fetchPosts(); // Fetch posts after an article is updated
      setEditing(false);
      setEditId(null);
      // Clear form fields
      setTitle('');
      setArticle('');
      setImageUrl(null);
      setAuthor('');
    } catch (error) {
      console.error('Error updating article:', error);
      toast.error('Error updating article');
    }
  };

  useEffect(() => {
    if (user) {
      fetchPosts();
    }
  }, [user]);

  return (
    <div className="login-container">
      {!user && (
        <form onSubmit={handleSubmit} className="login-form">
          <h3>Login</h3>
          <div className="input-group">
            <input
              type="email"
              className="input"
              placeholder="Enter Your Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
          <div className="input-group">
            <input
              type="password"
              className="input"
              placeholder="Enter Your Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>
          <button type="submit" className="buttonn">Submit</button>
        </form>
      )}
      {user && (
        <>
          <form className="add-user-form" onSubmit={handleReg}>
            <h1 className="user" onClick={() => setExtend((prev) => !prev)}>Add User</h1>
            {extend && (
              <div>
                <div className="input-group">
                  <input
                    type="email"
                    className="input"
                    placeholder="Enter Your Email"
                    value={addEmail}
                    onChange={(e) => setAddEmail(e.target.value)}
                  />
                </div>
                <div className="input-group">
                  <input
                    type="password"
                    className="input"
                    placeholder="Enter Your Password"
                    value={addPassword}
                    onChange={(e) => setAddPassword(e.target.value)}
                  />
                </div>
                <button className="buttonn">Add User</button>
              </div>
            )}
          </form>
          <div className="publish-section">
            {!editing ? (
              <>
                <div className="buttonn publish"ref={ref}>Publish An Article</div>
                <div className="popup">
                  <form onSubmit={handlePost}>
                    <input onChange={(e) => setTitle(e.target.value)} value={title} className="input title" type="text" placeholder="Title" />
                    <textarea onChange={(e) => setArticle(e.target.value)} value={article} name="message" className="textarea article" rows="10" cols="30" placeholder="Write Article"></textarea>
                    <h3>Image</h3>
                    <input type="file" onChange={handleImageUpload} className="input-file" />
                    <input type="text" placeholder='Author Name' onChange={(e) => setAuthor(e.target.value)} value={author} className="input author" />
                    <button className="buttonn publish-btn">
                    <span className='publish-span'>  Publish </span>
                    </button>
                  </form>
                </div>
              </>
            ) : (
              <>
                <div className="buttonn publish">Edit Article</div>
                <div className="popup">
                  <form onSubmit={handleUpdate}>
                    <input onChange={(e) => setTitle(e.target.value)} value={title} className="input title" type="text" placeholder="Title"  required/>
                    <textarea onChange={(e) => setArticle(e.target.value)} value={article} name="message" className="textarea article" rows="10" cols="30" placeholder="Write Article" required></textarea>
                    <h3>Image</h3>
                    <input type="file" onChange={handleImageUpload} className="input-file" required />
                    <input type="text" placeholder='Author Name' onChange={(e) => setAuthor(e.target.value)} value={author} className="input author" required />
                    <button className="buttonn">Update</button>
                  </form>
                </div>
              </>
            )}
          </div>
          <div className="articles-section">
            <h3>Published Articles</h3>
            <div className="articles-list">
              {posts.map(post => (
                <div key={post.id} className="article-card">
                  <img src={post.imageUrl} alt="Article" className="article-image" />
                  <h4>{post.title}</h4>
                  <p>{post.article}</p>
                  <p>By <strong>{post.author}</strong></p>

                  <div className="buttttt">
                  <button onClick={() => handleEdit(post.id, post.title, post.article, post.imageUrl, post.author)}  className=" edit-button">

                    <svg class="edit-svgIcon" viewBox="0 0 512 512">
                    <path d="M410.3 231l11.3-11.3-33.9-33.9-62.1-62.1L291.7 89.8l-11.3 11.3-22.6 22.6L58.6 322.9c-10.4 10.4-18 23.3-22.2 37.4L1 480.7c-2.5 8.4-.2 17.5 6.1 23.7s15.3 8.5 23.7 6.1l120.3-35.4c14.1-4.2 27-11.8 37.4-22.2L387.7 253.7 410.3 231zM160 399.4l-9.1 22.7c-4 3.1-8.5 5.4-13.3 6.9L59.4 452l23-78.1c1.4-4.9 3.8-9.4 6.9-13.3l22.7-9.1v32c0 8.8 7.2 16 16 16h32zM362.7 18.7L348.3 33.2 325.7 55.8 314.3 67.1l33.9 33.9 62.1 62.1 33.9 33.9 11.3-11.3 22.6-22.6 14.5-14.5c25-25 25-65.5 0-90.5L453.3 18.7c-25-25-65.5-25-90.5 0zm-47.4 168l-144 144c-6.2 6.2-16.4 6.2-22.6 0s-6.2-16.4 0-22.6l144-144c6.2-6.2 16.4-6.2 22.6 0s6.2 16.4 0 22.6z"></path>
                  </svg>


                  </button>
                  <button onClick={() => handleDelete(post.id)} className="buttunn delete-button">
                  <svg viewBox="0 0 448 512" class="svgIcon"><path d="M135.2 17.7L128 32H32C14.3 32 0 46.3 0 64S14.3 96 32 96H416c17.7 0 32-14.3 32-32s-14.3-32-32-32H320l-7.2-14.3C307.4 6.8 296.3 0 284.2 0H163.8c-12.1 0-23.2 6.8-28.6 17.7zM416 128H32L53.2 467c1.6 25.3 22.6 45 47.9 45H346.9c25.3 0 46.3-19.7 47.9-45L416 128z"></path></svg>
                  </button>
                  </div>
                  
                </div>
              ))}
            </div>
          </div>
        </>
      )}
      <ToastContainer />
    </div>
  );
};

export default Login;